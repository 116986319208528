// 
// Theme Name: New Clean Template
// datepicker.sass
//
// app/sass
//

.air-datepicker
    width: 300px
    font-family: 'Poppins', sans-serif
    background: #fff
    border: none
    margin: 0 auto
    position: relative !important
    &.-inline-
        color: #fff
        background: none
        &::after
            content: ""
            width: 86px
            height: calc(100% - 48px)
            background: linear-gradient(135deg, var(--blue), var(--purple__deep))
            border-radius: 12px
            position: absolute
            top: 48px
            right: 0
            z-index: -1
.air-datepicker-nav
    border: none
.air-datepicker-nav--title
    font-size: 24px
    font-weight: 600
.air-datepicker-nav--title:hover
    background: none
.air-datepicker.-inline- .air-datepicker-nav--title i
    color: #fff
    font-weight: 600
.air-datepicker.-inline- .air-datepicker-body--day-name
    color: #fff
    font-weight: 600
.air-datepicker-body--cells.-days-
    grid-auto-rows: 42px
.air-datepicker-cell.-day-
    width: 42px
    height: 42px
.air-datepicker.-inline- .air-datepicker-cell.-day-.-focus-
    background: #ffffff1a
    border-radius: 50%
.air-datepicker.-inline- .air-datepicker-cell.-day-.-current-,
.air-datepicker.-inline- .air-datepicker-cell.-day-.-selected-
    color: #fff
    background: linear-gradient(135deg, var(--blue), var(--purple__deep))
    border-radius: 50%
.air-datepicker-cell.-day-.-current-
    opacity: 0.75

.air-datepicker-body--day-names
    height: 42px
    font-size: 16px

.air-datepicker-nav--action:hover
    background: none
.air-datepicker-nav--action path
    stroke: #fff
    stroke-width: 3px