// 
// Theme Name: New Clean Template
// _dashboard.sass
//
// app/sass/layout/
//

.dashboard
    h2
        font-size: 48px
        font-weight: 700
        text-align: left
        margin: 0 0 40px 0

.dash
    &__wrap
        display: flex
    &__item
        width: 32%
        font-family: 'Poppins', sans-serif
        color: #fff
        font-weight: 700
        background: url(../../img/line--art.svg) no-repeat top 90px right 52px, $grad
        border-radius: 20px
        padding: 24px
        margin: 0 1.2% 0 0
        position: relative
        z-index: 1
        &.active
            border-radius: 20px 20px 0 0
    &__header
        font-size: 24px
        line-height: 24px
        display: flex
        align-items: center
        margin: 0 0 12px 0
    &__img
        width: 58px
        height: 58px
        flex: 0 0 58px
        margin: 0 12px 0 0
    &__price
        font-size: 32px
        line-height: 48px
    &__hint
        font-family: 'Lora', serif
        font-weight: 400
        margin: 0 0 24px 0
    &__btn
        width: 240px
        font-family: 'Lora', serif
        color: #fff
        text-align: center
        line-height: 40px
        background: var(--green)
        border-radius: 20px
        margin: 0 auto
        display: block
        &:hover
            background: var(--blue)
    &__subitem
        width: 100%
        font-family: 'Lora', sans-serif
        font-weight: 400
        background: $grad
        line-height: 42px
        border-radius: 0 0 20px 20px
        padding: 10px 24px
        display: none
        position: absolute
        top: 100%
        left: 0
    &__item.active &__subitem
        display: block
    &__subrow
        display: flex
        align-items: center
        justify-content: space-between

.invest
    &__content
        color: #fff
        background: $grad
        border-radius: 32px
        box-shadow: 0 10px 20px #00000040
        display: flex
        align-items: center
        justify-content: space-between
        padding: 56px 48px
        margin: 0 0 24px 0
        &.main
            background: url(../../img/invest__bg.jpg) no-repeat 50%
            background-size: cover
        h2
            font-size: 48px
            font-weight: 700
            text-align: left
            margin: 0 0 10px 0
        .btn
            padding: 0 64px
    &__total
        font-family: 'Poppins', sans-serif
        font-weight: 600

.line
    &__wrap
        max-width: 320px
        height: 12px
        background: #fff
        border-radius: 6px
        margin: 0 0 20px 0
    &__line
        width: 0%
        height: 12px
        background: var(--green)
        border-radius: 6px
        animation: growUp 2s forwards

@keyframes growUp
    0%
        width: 0%
    100%
        width: 40%

.earn
    h2
        font-size: 48px
        text-align: left
        margin: 0
    h4
        font-family: 'Lora', serif
        font-size: 16px
        text-align: left
        margin: 0 0 32px 0
    &__wrap
        display: flex
        justify-content: space-between
    &__item
        width: 49%
        background: $grad
        border-radius: 20px
        padding: 30px 60px
        margin: 0 1% 0 0
    &__tabs
        display: flex
        margin: 0 0 24px 0
    &__tab
        font-size: 12px
        color: #fff
        font-weight: 600
        line-height: 32px
        background: var(--green)
        border-radius: 16px
        padding: 0 20px
        margin: 0 10px 0 0
        cursor: pointer
        &.active
            background: var(--blue)
    &__btn
        font-family: 'Poppins', sans-serif
        color: #fff
        font-weight: 600
        line-height: 64px
        background: var(--blue)
        border-radius: 32px
        padding: 0 64px
        position: absolute
        top: 0
        right: 20px
        &:hover
            background: var(--green)

.offers
    margin-bottom: 100px
    h2
        font-size: 48px
        text-align: left
        margin: 0
    h4
        font-family: 'Lora', serif
        font-size: 16px
        text-align: left
        margin: 0 0 32px 0
    &__wrap
        display: flex
        justify-content: space-between
    .card
        width: 24%
    &__btn
        font-family: 'Poppins', sans-serif
        color: #fff
        font-weight: 600
        line-height: 64px
        background: var(--blue)
        border-radius: 32px
        padding: 0 64px
        position: absolute
        top: 0
        right: 20px
        &:hover
            background: var(--green)

@media (max-width: 992px)
    .invest
        &__content
            h2
                font-size: 40px
                padding: 0 20px 0 0

    .dash
        &__header
            font-size: 16px
        &__btn
            width: 100%

    .earn
        &__wrap
            flex-wrap: wrap
        &__item
            width: 100%
            margin: 0 0 24px 0
        &__tabs
            justify-content: center
        &__graph
            margin: 0 auto
            display: block

    .offers
        &__wrap
            padding: 0 0 20px 20px
            margin: 0 -20px
            overflow: auto
        .card
            width: 40%
            flex: 0 0 40%
            margin: 0 20px 0 0

@media (max-width: 768px)
    .topbar
        font-size: 12px
        text-align: left

    .invest
        &__content
            flex-wrap: wrap
            padding: 24px
            h2
                font-size: 24px
                line-height: 32px
        &__total
            margin: 0 0 24px 0

    .dash
        &__wrap
            flex-wrap: wrap
        &__item
            width: 100%
            margin: 0 0 24px 0

    .earn
        &__item
            padding: 20px
        &__tab
            font-size: 11px
        &__graph
            max-width: 100%
        &__btn
            max-width: 200px
            text-align: center
            padding: 0 10px
            margin: 0 auto
            display: block
            position: unset

    .offers
        margin-bottom: 48px
        .card
            width: 80%
            flex: 0 0 80%
            margin: 0 10px 0 0
        &__btn
            max-width: 200px
            text-align: center
            padding: 0 10px
            margin: 0 auto
            display: block
            position: unset