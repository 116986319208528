#projects {
  .swiper {
    @media (min-width: 1400px) {
      overflow: visible;
    }

    .swiper-button-disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
}

#blogs {
  .swiper {
    @media (min-width: 1400px) {
      overflow: visible;
    }

    .swiper-button-disabled {
      opacity: .5;
      pointer-events: none;
    }
  }
}