// 
// Theme Name: New Clean Template
// _infograph.sass
//
// app/sass/layout/
//

.infograph
    max-width: 740px
    display: flex
    margin: 0 0 140px 0
    &.alt
        margin-left: auto
    &__img
        flex: 0 0 250px
        margin: 0 24px 0 0
        [class$=alt] > &
            margin: 0 0 0 24px
        img
            width: 100%
            height: auto
    &__title
        font-family: $poppins
        font-size: 44px
        font-weight: 700
        margin: 0 0 20px 0
    &__subtitle
        font-size: 18px
        line-height: 1.5
        margin: 0 0 40px 0
    &__btn
        width: 200px
        font-family: $poppins
        color: #fff
        font-weight: 600
        text-align: center
        line-height: 64px
        background: $grad
        border-radius: 40px
        padding: 0 10px
        margin: 0 auto
        display: block
        cursor: pointer
        &:hover
            filter: brightness(1.1)

.security
    background: url(../../img/invest__bg--alt.svg) no-repeat 50%
    background-size: cover
    padding: 48px 0
    margin: 0
    h2
        color: #fff
        span
            color: var(--purple__deep)
    &__item
        max-width: 760px
        display: flex
        margin: 0 auto 48px auto
    &__img
        flex: 0 0 150px
        margin: 0 32px 0 0
        img
            width: 100%
            height: auto
    &__title
        font-family: $poppins
        font-size: 32px
        color: #fff
        margin: 0 0 16px 0
    &__subtitle
        font-size: 16px
        color: #fff
        line-height: 26px
    + .invest
        margin-top: 0

@media (max-width: 768px)
    .infograph
        flex-direction: column
        margin: 0 0 40px 0
        &__img
            width: 100%
            height: 150px
            text-align: center
            flex: auto
            margin: 0 0 10px 0
            [class$=alt] > &
                order: -1
                margin: 0 0 10px 0
            img
                width: auto
                height: 100%
        &__title
            font-size: 24px
            text-align: center
        &__subtitle
            font-size: 14px
            text-align: center
            margin: 0 0 32px 0
        &__btn
            line-height: 40px

    .security
        &__item
            flex-direction: column
        &__img
            height: 70px
            text-align: center
            flex: auto
            margin: 0 0 16px 0
            img
                width: auto
                height: 100%
        &__title
            font-size: 16px
            font-weight: 600
        &__subtitle
            font-size: 14px