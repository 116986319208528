// 
// Theme Name: New Clean Template
// _components.sass
//
// app/sass/layout/
//

.btn
    font-family: 'Poppins', sans-serif
    color: #fff
    font-weight: 600
    line-height: 36px
    white-space: nowrap
    background: var(--blue)
    border: 2px solid var(--blue)
    border-radius: var(--btn__radius)
    padding: 0 36px
    transition: 0.3s
    cursor: pointer
    &:hover
        background: var(--green)
        border-color: var(--green)
    &--stroke
        color: var(--purple__deep)
        background: none
        border: 2px solid var(--purple__deep)
        transition: background 0.3s
        &:hover
            color: #fff
            line-height: 40px
            background: linear-gradient(90deg, var(--blue), var(--green))
            border: 0px solid var(--blue)
            padding: 0 38px
    &--big
        line-height: 64px
        background: var(--blue)
        border: none
        border-radius: 42px
        padding: 0 46px
        transition: 0.4s
        &:hover
            background: linear-gradient(135deg, var(--green), var(--green))
            border: none
        &.alt
            line-height: 60px
            background: none
            border: 2px solid var(--green__light)
            transition: none
            &:hover
                line-height: 64px
                background: linear-gradient(135deg, var(--blue), var(--green))
                border: none
                padding: 0 48px
    &--grad
        background: linear-gradient(135deg, var(--blue), var(--green))
    &__wrap
        display: flex
        justify-content: center
    &--grad3
        background: $grad3
    &--light
        color: var(--green)
        border-color: var(--green)

.btns
    display: flex
    :first-child
        width: auto
        margin: var(--btn__offset)
    &--centered
        justify-content: center

.title
    &__big
        margin: 80px 0
        h1
            font-family: $poppins
            font-size: 56px
            font-weight: 700
            padding: 0 0 24px 0
            position: relative
            span
                background: var(--blue)
                background: $grad
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
            &::after
                content: attr(data-echo)
                font-size: 144px
                color: #F4F4F4
                line-height: 1
                position: absolute
                top: 0
                left: 0
                z-index: -1
    &__search
        width: 100%
        max-width: 480px
        font-size: 16px
        line-height: 64px
        letter-spacing: 0.04em
        background: url(../../img/search.svg) no-repeat 24px 50% var(--gray)
        border: none
        border-radius: 16px
        padding: 0 42px 0 64px

.title
    font-family: $poppins
    color: #fff
    text-align: center
    background: $grad
    padding: 48px 0
    margin: 0
    h1
        font-size: 74px
        line-height: normal
        margin: 0 0 8px 0
    h3
        max-width: 900px
        font-size: 20px
        font-weight: 400
        line-height: normal
        margin: 0 auto

.faq
    &__wrap
        width: 100%
        font-family: $poppins
        display: flex
    &__content
        flex-grow: 1
    &__side
        width: 280px
        flex: 0 0 280px
        padding: 0 120px 0 0
    &__nav
        color: #6E7191
        font-weight: 600
        text-align: center
        line-height: 64px
        border-radius: 16px
        position: relative
        cursor: pointer
        &.active
            color: #fff
            background: $grad
            &::after
                content: ''
                width: 8px
                height: 8px
                background: #fff
                border-radius: 50%
                position: absolute
                left: 50%
                bottom: 10px
                transform: translateX(-50%)
    &__group
        display: none
        &.active
            display: block
    &__item
        border-radius: 16px
        box-shadow: 0 10px 10px #00000040
        margin: 0 0 24px 0
    &__question
        font-family: $poppins
        font-size: 18px
        color: #fff
        font-weight: 600
        line-height: 64px
        background: $grad
        border: 2px solid transparent
        border-radius: 16px
        padding: 0 24px
        position: relative
        cursor: pointer
        &::after
            content: ''
            width: 24px
            height: 24px
            background: url(../../img/plus.svg) no-repeat 50%
            position: absolute
            top: 50%
            right: 24px
            transform: translateY(-50%)
        &.active
            color: var(--text)
            background: #fff
            border: 2px solid var(--blue)
            border-bottom: none
            border-radius: 16px 16px 0 0
            &::after
                background: url(../../img/minus.svg) no-repeat 50%
            + [class$=answer]
                max-height: unset
                border: 2px solid var(--blue)
                border-top: none
                border-radius: 0 0 16px 16px
                padding: 0 24px 24px 24px
    &__answer
        max-height: 0px
        font-family: 'Lora', sans-serif
        color: #4E4B66
        line-height: 32px
        padding: 0 24px
        overflow: hidden

.chart
    &__wrap
        display: flex
    &__info
        width: 50%
    &__title
        font-family: $poppins
        font-size: 26px
        font-weight: 700
        text-align: center
        line-height: 38px
    &__subtitle
        font-size: 18px
        color: #7C828A
        text-align: center
        margin: 0 0 32px 0
    &__pie
        width: 180px
        height: 180px
        border-radius: 50%
        margin: 0 auto
        position: relative
    &__value
        color: #fff
        font-weight: 600
        line-height: 24px
        position: absolute
        top: 80px
        right: 28px
        z-index: 1
    &__legend
        margin: 0 0 16px 0
    &__line-bg
        height: 8px
        background: #C5CDD8
        border-radius: 6px
    &__line
        height: 8px
        background: var(--blue)
        border-radius: 6px
        &--blue-pale
            background: var(--blue__pale)
        &--green-pale
            background: var(--green__pale)
        &--green
            background: var(--green)
        &--purple-deep
            background: var(--purple__deep)
    &__graph
        width: 50%
        padding: 0 40px
    &__legend
        [class$=row]
            margin: 0 0 8px 0
            span
                &:first-child
                    color: #7C828A
                &:last-child
                    font-weight: 700

.stage
    &__thead
        font-weight: 700
        text-align: center
        display: flex
        padding: 0 16px
        margin: 0 0 8px 0
        span
            flex: 1 1 0
            padding: 0 10px
            &:first-child
                flex: 0.5 1 0
            &:nth-child(3)
                flex: 2 1 0
            &:last-child
                flex: 4 1 0
    &__row
        font-size: 15px
        text-align: center
        line-height: 24px
        border: 2px solid var(--blue)
        border-right-color: var(--green)
        border-bottom-color: var(--green)
        border-radius: 16px
        box-shadow: 0 4px 18px #00000080
        display: flex
        padding: 12px 16px
        margin: 0 0 24px 0
        &:last-child
            margin: 0
        span
            display: flex
            flex: 1 1 0
            align-items: center
            justify-content: center
            padding: 0 8px
            &:first-child
                font-size: 12px
                color: #fff
                line-height: 40px
                border-radius: 24px
                flex: 0.5 1 0
                align-self: center
                &.approved
                    background: var(--green)
                &.pending
                    color: var(--text)
                    background: #F9F13A
                &.declined
                    background: #FC5C5C
            &:nth-child(3)
                border-left: 1px solid var(--text)
                border-right: 1px solid var(--text)
                flex: 2 1 0
            &:last-child
                flex: 4 1 0

.doc
    &__title
        font-family: $poppins
        font-size: 30px
        color: var(--text)
        font-weight: 700
        margin: 0 0 16px 0

    &__row
        text-align: center
        border: 2px solid var(--blue)
        border-right-color: var(--green)
        border-bottom-color: var(--green)
        border-radius: 16px
        display: flex
        padding: 12px 16px
        margin: 0 0 24px 0
        span
            flex: 1 1 0
            padding: 0 8px
            &:nth-child(2)
                color: var(--blue)
                border-left: 1px solid var(--text)
                border-right: 1px solid var(--text)
                flex: 2 1 0
            &:last-child
                flex: 4 1 0
    &__thead
        font-weight: 700
        border: none
        padding: 0 16px
        margin: 0 0 4px 0
        span
            color: var(--text) !important
            padding: 0 10px
            border: none !important

fieldset
    border: none
    padding: 0
    margin: 0 0 24px 0
    -webkit-appearance: none
    ul
        list-style-type: none
        padding: 0
        margin: 0
        li
            padding: 8px 0
            &::after
                display: none
    legend
        font-size: 14px
        line-height: 20px
        padding: 0
        margin: 0 0 16px 0
        opacity: 0.9
        span
            color: var(--green)
    label
        font-size: 14px
        display: flex
        align-items: center
        cursor: pointer
        span
            font-weight: 700
            margin: 0 4px
    input
        width: 32px
        height: 32px
        background: #D9DBE9
        border-radius: 50%
        margin: 0 8px 0 0
        -webkit-appearance: none
        &:checked
            background: url(../../img/check.svg) no-repeat 50% #fff

.breadcrumbs
    margin: 52px 0 46px 0
    .container
        display: flex
        align-items: center
    &__back
        width: 48px
        height: 48px
        background: url(../../img/back.svg) no-repeat 0 50%
    &__title
        font-family: $poppins
        font-size: 32px
        font-weight: 700
        line-height: 48px
        span
            background: var(--blue)
            background: $grad
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

.card
    font-size: 14px
    color: #7f7f7f
    background: #fff
    border-radius: 20px
    box-shadow: 0 10px 10px #00000040
    padding: 15px
    overflow: hidden
    &__slider
        max-width: 980px
        display: flex
        margin: 0 auto 48px auto
    &__img
        height: 258px
        margin: -15px
        position: relative
        &::after
            content: ''
            width: 100%
            height: 100%
            background: linear-gradient(0deg, #2FA9CEe6, #2FA9CE00)
            position: absolute
            top: 0
            left: 0
    &__flex
        display: flex
        align-items: center
        justify-content: space-between
    &__header
        color: #fff
        margin: -60px 0 20px 0
        position: relative
        z-index: 3
    &__id
        font-size: 20px
        line-height: 32px
        span
            font-weight: 700
            margin: 0 8px 0 0
    &__class
        font-size: 20px
    &__price-main
        font-family: 'Poppins', sans-serif
        font-size: 32px
        font-weight: 700
        background: linear-gradient(135deg, var(--blue), var(--green))
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
    &__round
        height: 92px
        border-radius: 50%
        box-shadow: 0 0 0 8px #EFF0F6 inset
        position: relative
        [class$=rate]
            width: 100%
            position: absolute
            top: 30px
            left: 0
    &__progress
        stroke-dashoffset: 230
        transform: rotate(270deg) rotateX(180deg)
    &__rates
        font-size: 16px
        margin: 30px 0
    &__rate
        text-align: center
        span
            color: var(--text)
            font-weight: 600
            display: block
    &__btn
        .btn
            text-align: center
            display: block

.tab
    &__navs
        display: flex
        justify-content: center
        margin: 0 0 48px 0
    &__navc
        background: var(--gray)
        border-radius: 32px
        display: flex
    &__nav
        font-family: 'Poppins', sans-serif
        color: #B0B0B0
        font-weight: 600
        line-height: 64px
        letter-spacing: 0.05em
        background: var(--gray)
        padding: 0 48px
        cursor: pointer
        &:first-child
            border-radius: 32px 0 0 32px
        &:last-child
            border-radius: 0 32px 32px 0
            &.active
                background: var(--green)
        &.active
            color: #fff
            background: var(--blue)
            border-radius: 32px
    &__item
        max-width: 600px
        margin: 0 auto
        display: none
        &.active
            display: block
        h2
            text-align: left

.blog
    &__item
        background: #fff
        border-radius: 20px
        box-shadow: 0 10px 10px #00000040
        padding: 15px
        margin-bottom: 30px
        overflow: hidden
    &__img
        height: 258px
        margin: -15px
    &__date
        font-size: 12px
        color: var(--blue)
        margin: 30px 0 0 0
    &__title
        font-family: 'Poppins', sans-serif
        font-size: 24px
        font-weight: 700
        margin: 0 0 15px 0
    &__description
        margin: 0 0 20px 0
    .btn
        max-width: 240px
        text-align: center
        margin: 0 auto
        display: block

.hero
    &__item
        display: flex
        background: #fff
        box-shadow: 0 10px 20px #00000040
        border-radius: 20px
        margin: 0 0 30px 0
        &--single
            border-radius: 20px 20px 0 0
            box-shadow: none
            margin: 0 -24px
        &--single
            .hero
                &__img
                    img
                        border-radius: 20px 0 0 0
                &__content
                    border-bottom: 1px solid #00000030
    &__img
        flex: 0 0 460px
        img
            border-radius: 20px 0 0 20px
    &__content
        display: flex
        flex-direction: column
        padding: 24px
        position: relative
    &__date
        font-size: 14px
        color: var(--blue)
        margin: 0 0 10px 0
    &__title
        font-family: $poppins
        font-size: 36px
        font-weight: 700
        line-height: 40px
        margin: 0 0 24px 0
    &__description
        line-height: 1.5
    &__sep
        height: 1px
        background: #00000033
        margin: 24px 0
    &__share
        font-family: $poppins
        font-weight: 700
        display: flex
        align-items: center
        position: absolute
        right: 24px
        bottom: 24px
        cursor: pointer
    &__icon
        width: 38px
        height: 38px
        background: url(../../img/icons/share--w.svg) no-repeat 50%, $grad3
        border-radius: 50%
        margin: 0 0 0 8px

.post
    &__content
        border-radius: 20px
        box-shadow: 0 10px 20px #00000040
        padding: 0 24px
        overflow: hidden
        h2
            font-family: $poppins
            font-size: 36px
            font-weight: 800
            text-align: left
            line-height: normal
            margin: 0 0 24px 0
    &__p
        margin: 24px 0
    &__img
        margin: 24px -24px 32px -24px
        span
            text-align: center
            margin: 10px 0
            display: block
    &__sep
        height: 1px
        background: #00000030
        margin: 48px -24px 20px -24px
    &__footer
        display: flex
        justify-content: center
        padding: 0 0 24px 0
        position: relative
    &__top
        font-family: $poppins
        font-size: 30px
        font-weight: 700
        line-height: 56px
        display: flex
        align-items: center
        cursor: pointer
    &__icon
        width: 32px
        height: 32px
        background: url(../../img/icons/top.svg) no-repeat 50%
        margin: 0 0 0 4px
    &__share
        display: flex
        background: $grad3
        border-radius: 40px
        padding: 16px 8px
        position: absolute
        top: 0px
        right: 0
        a
            width: 24px
            height: 24px
            margin: 0 8px

.pagination
    display: flex
    justify-content: center
    margin: 24px 0
    &__item
        width: 54px
        height: 54px
        font-family: $poppins
        font-size: 20px
        font-weight: 700
        display: flex
        align-items: center
        justify-content: center
        background: #fff
        border-radius: 50%
        box-shadow: 0 4px 10px #0000001a
        margin: 0 4px
        cursor: pointer
        &.active, &:hover
            color: #fff
            background: $grad3

.author
    display: flex
    margin: auto 0 0 0
    &__img
        height: 38px
        flex: 0 0 38px
        margin: 0 8px 0 0
    &__title
        font-family: $poppins
        font-weight: 700
    &__date
        font-size: 10px

.form
    &__wrap
        display: flex
        justify-content: center
        margin: 0 0 48px 0
    label
        margin: 0 0 8px 0
    input
        width: 100%
        font-family: 'Lora', sans-serif
        font-size: 16px
        line-height: 64px
        background: #fff
        border: none
        border-radius: 40px
        box-shadow: 0 8px 18px #00000033
        padding: 0 24px
        margin: 0 0 16px 0
        display: block
        &[type=email]
            background: url(../../img/icons/email.svg) no-repeat 24px 50%
            background-color: #fff
            padding: 0 24px 0 60px
        &[type=password]
            background: url(../../img/icons/lock.svg) no-repeat 24px 50%
            background-color: #fff
            padding: 0 24px 0 60px
        &[type=checkbox]
            width: 32px
            height: 32px
            background: #E2E2E2
            border-radius: 50%
            flex: 0 0 32px
            padding: 0
            margin: 0 16px 0 0
            cursor: pointer
            -webkit-appearance: none
            &:checked
                background: url(../../img/icons/check.svg) no-repeat 50% var(--purple__deep)
        &[type=file]
            padding: 0 60px 0 24px
            cursor: pointer
            &::file-selector-button
                display: none
            &:invalid
                background: url(../../img/icons/doc-g.svg) no-repeat top 50% right 24px #fff
            &:valid
                background: url(../../img/icons/check--w.svg) no-repeat top 50% right 24px, $grad3
        &:read-only
            color: var(--gray)
        &.icon
            &__networth
                background: url(../../img/icons/networth.svg) no-repeat 24px 50% #fff
                padding: 0 24px 0 60px
            &__percent
                background: url(../../img/icons/percent.svg) no-repeat 24px 50% #fff
                padding: 0 24px 0 60px
            &__user
                background: url(../../img/icons/user.svg) no-repeat 24px 50% #fff
                padding: 0 24px 0 60px
            &__email
                background: url(../../img/icons/email-g--alt.svg) no-repeat 24px 50% #fff
                padding: 0 24px 0 60px
            &__phone
                background: url(../../img/icons/phone-g.svg) no-repeat 28px 50% #fff
                padding: 0 24px 0 60px
            &__calendar
                background: url(../../img/icons/calendar-g.svg) no-repeat 24px 50% #fff
                padding: 0 24px 0 60px
            &__lock
                background: url(../../img/icons/lock-g--alt.svg) no-repeat 24px 50% #fff
                padding: 0 24px 0 60px
            &__doc
                background: url(../../img/icons/doc-g.svg) no-repeat top 50% right 24px #fff
                padding: 0 60px 0 24px
    textarea
        width: 100%
        height: 190px
        font-family: 'Lora', serif
        font-size: 16px
        background: #fff
        border: none
        border-radius: 16px
        box-shadow: 0 8px 18px #00000033
        padding: 24px
        margin: 0 0 24px 0
    button
        width: 100%
        font-family: $poppins
        font-size: 16px
        color: #fff
        font-weight: 600
        text-align: center
        line-height: 60px
        background: $grad3
        border: none
        border-radius: 40px
        margin: 40px 0 8px 0
        display: block
        cursor: pointer
        &:hover
            filter: brightness(1.1)
    &__glabel
        color: #fff
        display: flex
        align-items: center
        cursor: pointer
        p
            line-height: 1.5
            margin: 0
            a
                font-weight: 600
                text-decoration: underline
    &__tlabel
        font-family: $poppins
        font-size: 14px
        color: #6E7191
        font-weight: 600
        margin: 24px
        position: absolute
        + textarea
            padding-top: 52px
    &__hint
        font-size: 14px
        color: #fff
        a
            color: var(--purple__deep)
            text-decoration: underline
    &__sep
        width: 100%
        height: 2px
        background: #ffffff4d
        margin: 32px 0
    &__btn
        width: 100%
        font-family: "Poppins", sans-serif
        font-size: 16px
        color: var(--purple__deep)
        font-weight: 600
        text-align: center
        line-height: 60px
        border: 2px solid var(--purple__deep)
        border-radius: 40px
        display: block
        cursor: pointer
        &:hover
            color: #fff
            background: var(--purple__deep)
    &__info
        font-size: 12px
        color: var(--green__pale)
        font-weight: 700
        margin: -12px 0 24px 0
        position: relative
    &__info-content
        width: 290px
        font-size: 14px
        color: var(--text)
        font-weight: 400
        background: #fff
        border-radius: 16px
        box-shadow: 0 8px 18px #00000033
        padding: 16px 24px
        position: absolute
        top: 30px
        left: 120px
        z-index: 4
        opacity: 0
        transition: 0.3s
        pointer-events: none
        :hover > &
            opacity: 1
        &::after
            content: ''
            width: 0
            height: 0
            border-style: solid
            border-width: 0 14px 17px 14px
            border-color: transparent transparent #fff transparent
            position: absolute
            top: -10px
            left: 14px
            z-index: 1
    label
        &[for=file]
            line-height: 64px
            background: url(../../img/icons/upload.svg) no-repeat top 50% right 24px #fff
            border-radius: 40px
            box-shadow: 0 8px 18px #00000033
            padding: 0 24px
            margin: 0 0 24px 0
            cursor: pointer
            &.uploaded
                background: url(../../img/icons/uploaded.svg) no-repeat top 50% right 24px, $grad
            input
                display: none
    &__row
        display: flex
    &__edit
        font-size: 16px
        font-weight: 600
        line-height: 32px
        background: url(../../img/icons/change--w.svg) no-repeat 100% 50%
        padding: 0 34px 0 0
        cursor: pointer
    &__group
        max-width: 560px
        margin: 0 auto 24px auto
        position: relative
        label
            font-weight: 600
            line-height: 24px
            margin: 0 0 8px 0
            display: block
        input
            font-weight: 700
            margin: 0
        button
            width: 200px
            margin: 0
            line-height: 64px
            background: $grad
            position: absolute
            top: 30px
            right: 0
    &__action
        width: 40%
        display: flex
        align-items: center
        justify-content: flex-end
        button
            width: 100%
            background: $grad3
            margin: 0 0 0 10px
            position: unset
        &--empty
            margin: 0 !important
    &--left &__group
        margin: 0 0 24px 0

.select
    &__custom
        color: var(--text)
        font-weight: 600
        margin: 0 0 24px 0
        position: relative
        select
            display: none

/*style the arrow inside the select element:*/
.select-selected:after
  position: absolute
  content: ""
  top: 28px
  right: 24px
  width: 0
  height: 0
  border: 8px solid transparent
  border-width: 8px 7px 0 7px
  border-color: var(--blue) transparent transparent transparent

.select-selected.select-arrow-active
    z-index: 9
.select-selected.select-arrow-active + .select-items
    z-index: 8

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after
    border-width: 7px 0px 7px 8px
    border-color: transparent transparent transparent var(--green)
    top: 24px
    right: 26px

/*style the items (options), including the selected item:*/
.select-selected
    line-height: 64px
    background: #fff
    box-shadow: 0 8px 18px #00000033
    border-radius: 40px
    padding: 0 24px
    position: relative
    z-index: 3
    cursor: pointer
    user-select: none

/*style items (options):*/
.select-items
    background: #fff
    border-radius: 0 0 32px 32px
    box-shadow: 0 8px 18px #00000033
    padding: 30px 0 20px 0
    position: absolute
    top: calc(100% - 30px)
    left: 0
    right: 0
    z-index: 1
    overflow: hidden

.select-items div
    line-height: 64px
    padding: 0 24px
    cursor: pointer
    user-select: none

/*hide the items when the select box is closed:*/
.select-hide
  display: none

.select-items div:hover, .same-as-selected
    color: #fff
    background: var(--green)

.icon
    &__investment
        .select-selected
            background: url(../../img/icons/pig.svg) no-repeat 22px 50% #fff
            background-size: 28px
            padding-left: 64px
    &__purpose
        .select-selected
            background: url(../../img/icons/leaves.svg) no-repeat 22px 50% #fff
            background-size: 28px
            padding-left: 64px

.subscribe
    width: 100%
    max-width: 680px
    background: #fff
    border-radius: 40px
    display: flex
    input
        &[type=text]
            width: 100%
            max-width: 480px
            font-size: 16px
            color: var(--gray)
            line-height: 64px
            border: none
            border-radius: 40px
            box-shadow: none
            padding: 0 40px
            margin: 0
            &::placeholder
                color: var(--gray)
            &:focus
                outline: none
        &[type=submit]
            width: auto
            font-size: 16px
            color: #fff
            font-weight: 600
            line-height: 64px
            letter-spacing: 0.05em
            background: var(--blue)
            border: none
            border-radius: 40px
            box-shadow: none
            flex-grow: 1
            padding: 0 20px
            margin: 0
            cursor: pointer
            -webkit-appearance: none
            &:hover
                background: var(--green)

.text
    &__green
        font-size: 30px
        color: var(--green)
        font-style: italic
        text-align: center

.about-us__title
    margin: 60px 0

.principles
    &__title
        margin: 0 0 60px 0

.info
    &__img
        max-width: 100%
        height: auto
        margin: 0 auto 20px auto
        display: block

.block
    padding: 48px
    margin: 0 0 48px 0
    &--green
        background: var(--green__light)
    &__subtitle
        font-size: 20px
        font-weight: 700
        line-height: 30px
        margin: 0 0 20px 0
    &__item
        height: 100%
        background: var(--green__dark)
        padding: 32px 24px
    &__img
        display: flex
        margin: 0 0 15px 0
        img
            width: 24px
            height: 24px
            margin: 0 10px 0 0
    &__description
        color: var(--text__light)
        line-height: 24px

.feature
    &__item
        text-align: center
    &__img
        width: 100%
        height: 24px
        margin: 0 0 10px 0
        img
            object-fit: contain
        .animated &
            animation: rollIn 1s forwards
    &__title
        font-size: 20px
        font-weight: 700
        line-height: 1.5
        margin: 0 0 10px 0
    &__description
        max-width: 280px
        color: var(--text__light)
        line-height: 24px
        letter-spacing: 0.02em
        margin: 0 auto 10px
        &:last-child
            margin: 0 auto 30px
        &.saturated
            color: var(--text)
        > a
            width: fit-content
            color: var(--text)
            line-height: 32px
            //background: url(../../img/icons/arrow-right.svg) no-repeat top 50% right 6px
            padding: 0 32px 0 0
            margin: 20px 0
            display: block
            transition: 0.2s linear
            &:hover
                background-position: top 50% right 0
    &__logos
        margin: 30px 0 0 0
        img
            margin: 0 20px 20px 0
            &:last-child
                margin: 0 0 20px 0

@keyframes rollIn
    0%
        opacity: 0
        transform: rotate(-30deg) translateX(-20px) scale(1.5)
    100%
        opacity: 1
        transform: rotate(0) translateX(0) scale(1)

.featured
    &__slide
        height: auto !important
        display: flex
        align-items: center
        justify-content: center
        img
            max-width: 150px
            height: auto

.ready
    &__item
        text-align: center
        //background: url(../../img/ready-bg.svg) no-repeat 50% 0%
        border-right: 1px solid #0000001a
        padding: 58px 0
    &__title
        font-size: 20px
        font-weight: 700
        margin: 0 0 10px 0
    &__description
        max-width: 280px
        color: var(--text__light)
        line-height: 24px
        margin: 0 auto 52px auto
    &__link
        color: #fff
        font-weight: 700
        line-height: 48px
        //background: url(../../img/icons/arrow-right-w.svg) no-repeat top 50% right 40px var(--main)
        padding: 0 64px 0 44px
        display: inline-block
        cursor: pointer
        transition: 0.3s
        &:hover
            background-position: top 50% right 32px
            background-color: var(--main__hover)
        &.animated
            animation: flowIn 1s forwards

.sector
    &__item
        height: 100%
        color: #fff
        display: flex
        flex-direction: column
        padding: 48px
        position: relative
        transition: 0.3s
        &:hover
            filter: brightness(1.2)
        &::after
            content: ''
            width: 100%
            height: 100%
            background: #00000080
            position: absolute
            top: 0
            left: 0
        > div
            position: relative
            z-index: 1
    &__title
        font-size: 48px
        font-weight: 700
        margin: 0 0 58px 0
    &__subtitle
        font-size: 20px
        font-weight: 700
        line-height: 30px
        margin: 0 0 10px 0
    &__num
        font-size: 48px
        font-weight: 700
        line-height: 60px
        margin: auto 0 10px 0
    &__description
        font-size: 20px
        color: var(--text__light--alt)
        line-height: 30px

.visit
    &__item
        text-align: center
        border-right: 1px solid #0000001a
        display: flex
        flex-direction: column
        align-items: center
        padding: 30px 0
    &__img
        height: 38px
        text-align: center
        margin: 0 0 30px 0
        img
            max-width: 260px
            object-fit: contain
    &__title
        min-height: 36px
        font-weight: 700
        margin: 0 0 52px 0
    &__description
        width: 260px
        color: var(--text__light)
        line-height: 24px
        margin: 0 0 20px 0

.step
    &__item
        max-width: 268px
        background: var(--green__dark)
        padding: 30px 40px
        margin: 0 auto 100px auto
    &__num
        font-size: 30px
        font-weight: 700
        line-height: 40px
        margin: 0 0 10px 0
    &__title
        font-weight: 700

.team
    &__item
        text-align: center
        margin: 0 0 58px 0
        display: block
        cursor: pointer
    &__img
        max-width: 300px
        //background-image: url(../../img/team1-bg.svg)
        background-repeat: no-repeat
        background-position: 50% 75%
        background-size: contain
        margin: 0 auto 20px auto
        position: relative
        clip-path: circle(0% at 50% 50%)
        img
            width: 75%
            border-radius: 50%
            object-fit: contain
        &--second
            //background-image: url(../../img/team2-bg.svg)
            animation-delay: 0.2s
        &--third
            //background-image: url(../../img/team3-bg.svg)
            animation-delay: 0.4s
        &.animated
            animation-name: roundIn
            animation-duration: 1.4s
            animation-fill-mode: forwards
        &--overlay
            width: 220px
            height: 220px
            //background: url(../../img/profile-overlay.svg) no-repeat 50%
            position: absolute
            top: -20px
            left: 15px
    &__title
        font-size: 20px
        font-weight: 700
        line-height: 30px
        margin: 0 0 8px 0
    &__position
        color: var(--text__light)
        font-style: italic
        margin: 0 0 10px 0
    &__description
        color: var(--text__light)
        line-height: 24px
    &__bio
        color: var(--text__light)
        line-height: 24px

@keyframes roundIn
    0%
        clip-path: circle(0% at 50% 50%)
    100%
        clip-path: circle(100.0% at 50% 50%)

.sb
    &__banner
        width: 100%
        height: 468px
        text-align: center
        margin: 30px 0
        img
            max-width: 100%

.sb-features
    .container
        //background: url(../../img/art-arrow-down.svg) no-repeat 100% 100%
    &__item
        background: #F5F9F7
        padding: 34px 20px
        margin: 20px 0 0 0
    &__title
        min-height: 60px
        font-size: 18px
        font-weight: 700
        text-align: center
        line-height: 30px
        margin: 0 0 24px 0
    &__icon
        text-align: center

.career
    &__item
        text-align: center
        margin: 0 0 58px 0
    &__img
        width: 100%
        height: 24px
        margin: 0 0 10px 0
        img
            object-fit: contain
    &__title
        font-size: 20px
        font-weight: 700
        margin: 0 0 10px 0
    &__description
        max-width: 280px
        line-height: 24px
        margin: 0 auto

.feature
    &__item
        .who-u-are &
            margin: 0 0 58px 0
    &__title
        font-size: 18px
        line-height: 24px

.who-u-are
    h2
        margin: 0 0 58px 0

.process
    &__item
        background: var(--green__light)
        padding: 30px 40px
        margin: 0 0 48px 0
    &__top
        display: flex
        justify-content: space-between
        margin: 0 0 30px 0
    &__num
        font-size: 30px
        font-weight: 700
        margin: 0 0 10px 0
    &__hint
        font-weight: 700
    &__time
        font-weight: 700
        line-height: 64px
        //background: url(../../img/pillow.svg) no-repeat 50%
        background-size: contain
        padding: 0 30px
    &__description
        min-height: 48px
        color: var(--text__light)
        line-height: 24px

.start
    &__btn
        width: 260px
        color: #fff
        font-weight: 700
        text-align: center
        line-height: 58px
        background: var(--text)
        margin: 0 auto
        display: block
        cursor: pointer

.list
    &__wrap
        width: 100%
        display: flex
    &__content
        height: 100%
        display: flex
        flex-wrap: wrap
        flex-grow: 1
        justify-content: space-between
        .card
            width: 31%
            margin: 0 0 24px 0
    &__rows
        width: 100%
    &__row
        width: 100%
        height: 64px
        font-size: 14px
        background: #fff
        border-radius: 16px
        box-shadow: 0 4px 14px #00000040
        display: flex
        align-items: center
        padding: 0 16px
        margin: 0 0 24px 0
        position: relative
        &--small
            font-size: 12px
            .list
                &__cell
                    padding: 0 8px 0 0
                    &:first-child
                        flex: 2 1 0
                    &.flex-1
                        flex: 1 1 0
        &--haschild
            &.active
                border-radius: 16px 16px 0 0
                .list
                    &__subrows
                        display: block
                    &__dropdown
                        background: url(../../img/icons/arrow__up--grad.svg) no-repeat 50%
    &__subrows
        width: 100%
        background: #fff
        border-radius: 0 0 16px 16px
        box-shadow: 0 4px 14px #00000040
        padding: 8px 0
        display: none
        position: absolute
        top: 100%
        left: 0
        z-index: 3
    &__subrow
        height: 42px
        display: flex
        align-items: center
        padding: 0 16px
        .list
            &__row
                height: 42px
                background: none
                box-shadow: none
                margin: 0
    &__subcell
        flex: 1 1 0
        padding: 0 8px 0 0
        &:first-child
            flex: 2 1 0
    &__cell
        flex: 1 1 0
        &.active
            color: var(--green)
        &.restructured
            color: var(--red)
        &.delayed
            color: var(--orange)
        &.up
            &::after
                content: ''
                width: 14px
                height: 14px
                background: url(../../img/arrow__up--green.svg) no-repeat 50%
                margin: 0 0 0 4px
                display: inline-block
                transform: translateY(1px)
        &.down
            &::after
                content: ''
                width: 14px
                height: 14px
                background: url(../../img/arrow__down--red.svg) no-repeat 50%
                margin: 0 0 0 4px
                display: inline-block
                transform: translateY(2px)
        &--grow-3
            flex: 3 1 0
        &-col
            font-size: 14px
            text-align: center
            display: flex
            flex-direction: column
            a
                color: var(--blue)
    &__dropdown
        width: 24px
        height: 24px
        background: url(../../img/icons/arrow__down--grad.svg) no-repeat 50%
        margin: 0 0 0 auto
        cursor: pointer
    &__status
        width: 16px
        height: 16px
        background: #16CB9C
        border-radius: 50%
        margin: 0 auto
        &--red
            background: #FC5C5C
    &__btn
        font-size: 16px
        color: #fff
        font-weight: 600
        line-height: 32px
        background: $grad
        border-radius: 32px
        padding: 0 15px
        &:nth-last-child(2)
            color: var(--blue)
            line-height: 30px
            background: none
            border: 1px solid var(--blue)
            margin: 0 8px 0 0
    &__cell-wrap
        display: flex
        align-items: center
    &__cell-img
        width: 30px
        height: 30px
        flex: 0 0 30px
        margin: 0 8px 0 0
    &__cell-info
        a
            color: var(--blue)
            line-height: 24px
            display: block
    &__cell-project
        display: flex
        justify-content: space-evenly
        span
            color: var(--green)

.alert
    display: flex
    align-items: center
    border-radius: 16px
    box-shadow: 0 10px 20px #00000040
    padding: 24px 40px
    margin: 0 0 24px 0
    position: relative
    overflow: hidden
    &::after
        content: ''
        width: 16px
        height: 100%
        background: #FEC022
        position: absolute
        top: 0
        left: 0
    &--error
        &::after
            background: #FF0000
    &--notification
        &::after
            background: #2F87EB
    &--information
        &::after
            background: none
    &--success
        &::after
            background: #87CA81
    &__icon
        width: 46px
        height: 46px
        font-family: $poppins
        font-size: 32px
        color: #fff
        font-weight: 800
        text-align: center
        line-height: 46px
        background: #FEC022
        border-radius: 50%
        margin: 0 24px 0 0
    &--warning &__icon
        &::after
            content: '!'
    &--error &__icon
        line-height: 42px
        background: #FF0000
        &::after
            content: 'x'
    &--information &__icon
        font-family: revert
        background: #2F87EB
        &::after
            content: 'i'
    &--success &__icon
        background: #87CA81
        &::after
            content: '+'
    &__title
        font-family: $poppins
        font-size: 24px
        font-weight: 700
        line-height: 28px
    &__date
        font-size: 12px
        margin: 0 0 30px 0

.new-messages
    font-family: $poppins
    font-size: 24px
    color: #00000040
    display: flex
    align-items: center
    font-weight: 600
    margin: 32px 0
    &::before
        content: ''
        width: 32px
        height: 2px
        background: #00000040
        margin: 0 8px 0 0
    &::after
        content: ''
        width: 32px
        height: 2px
        flex-grow: 1
        background: #00000040
        margin: 0 0 0 8px

.sidebar
    width: 280px
    flex: 0 0 280px
    padding: 0 30px 0 0
    &__title
        font-weight: 700
        margin: 0 0 10px 0

.progress
    &__wrap
        height: 6px
        background: var(--gray)
        border-radius: 6px
        margin: 4px 0
    &__line
        height: 6px
        background: $grad
        border-radius: 6px

.input
    &__wrap
        width: 100%
        max-width: 100%
        display: flex
        margin: 0 0 24px 0
        input
            max-width: 118px
            font-size: 16px
            font-weight: 700
            text-align: center
            line-height: 64px
            border: none
            border-radius: 16px
            box-shadow: 0 4px 10px #00000033
            padding: 0 10px
            &:first-child
                margin: 0 15px 0 0

.select
    &__wrap
        line-height: 64px
        background: #fff
        border-radius: 16px
        box-shadow: 0 4px 10px #00000033
        margin: 0 0 24px 0
    &__title
        font-weight: 700
        background-image: url(../../img/arrow__down.svg)
        background-repeat: no-repeat
        background-position: top 50% right 28px
        border-radius: 16px
        box-shadow: 0 4px 10px #00000033
        padding: 0 24px
        cursor: pointer
        &.active
            background-image: url(../../img/arrow__up.svg)
            + .select__dropdown
                max-height: 1800px
                transition: 1s
    &__all
        font-weight: 700
        border-bottom: 2px solid #0D738133
        padding: 0 24px
        margin: 0 0 8px 0
        position: relative
        cursor: pointer
        &::after
            content: ''
            width: 20px
            height: 20px
            background-repeat: no-repeat
            background-position: 50%
            border: 2px solid var(--blue)
            border-radius: 4px
            position: absolute
            top: 50%
            right: 24px
            transform: translateY(-50%)
        &:hover
            &::after
                border-color: var(--green)
        &.active
            &::after
                background-image: url(../../img/check__w.svg), $grad
                border-color: var(--blue)
    &__dropdown
        max-height: 0
        display: flex
        flex-direction: column
        overflow: hidden
        transition: 0.4s cubic-bezier(0, 1, 0, 1)
        label
            font-family: $poppins
            font-weight: 600
            line-height: 58px
            display: none
            align-items: center
            justify-content: space-between
            padding: 0 24px
            cursor: pointer
            &:hover
                input
                    border: 2px solid var(--green)
            &:nth-child(2)
                display: flex
            &:nth-child(3)
                display: flex
            &:nth-child(4)
                display: flex
            &:nth-child(5)
                display: flex
        &.active
            label
                display: flex
        input
            width: 24px
            height: 24px
            background-repeat: no-repeat
            background-position: 50%
            border: 2px solid var(--blue)
            border-radius: 4px
            margin: 0
            cursor: pointer
            -webkit-appearance: none
            &:checked
                background-image: url(../../img/check__w.svg), $grad
                border-color: var(--blue) !important
    &__btn
        color: var(--blue)
        font-weight: 700
        text-align: center
        line-height: 36px
        border: 2px solid var(--blue)
        border-radius: 40px
        margin: 16px 24px
        transition: 0.3s
        cursor: pointer
        &:hover
            color: #fff
            background: var(--blue)


.bignav
    font-family: $poppins
    font-size: 32px
    font-weight: 500
    padding: 32px 0 58px 0
    position: relative
    &::after
        content: ''
        width: 100%
        height: 2px
        background: linear-gradient(135deg, var(--blue), var(--green))
        position: absolute
        top: 100%
        left: 0
        opacity: 0.5
    .container
        display: flex
        align-items: center
        justify-content: center
    &__item
        color: var(--text__light--alt)
        padding: 0 24px
        position: relative
        &.active
            color: var(--text)
    &__bullet
        width: 24px
        height: 24px
        background: $grad
        border-radius: 12px
        position: absolute
        transition: 0.4s

.features
    background: url(../../img/login__bg.svg) no-repeat 50% / cover
    padding: 48px 0
    .container
        display: flex
        flex-wrap: wrap
    &__item
        width: 49%
        background: #fff
        border-radius: 20px
        padding: 32px 24px
        margin: 0 1% 20px 0
        &:nth-child(2n)
            margin: 0 0 20px 1%
    &__title
        font-family: $poppins
        font-size: 24px
        font-weight: 700
        display: flex
        align-items: center
        margin: 0 0 16px 0
        img
            margin: 0 16px 0 0

.roadmap
    &__slider
        border: 4px solid var(--blue)
        border-radius: 24px
        display: flex
        margin: 0 0 120px 0
    &__item
        background: #fff
        border-radius: 16px
        box-shadow: 0 8px 18px #00000033
        padding: 24px
        margin: 16px
        &.slick-current
            color: #fff
            background: $grad
    &__title
        font-family: $poppins
        font-size: 24px
        font-weight: 700
        margin: 0 0 16px 0
    .slick
        &-list
            padding: 0
        &-next
            background: url(../../img/arrow__right--alt.svg) no-repeat 50%
            top: calc(100% + 60px)
            right: calc(50% - 80px)
        &-prev
            background: url(../../img/arrow__left--alt.svg) no-repeat 50%
            top: calc(100% + 60px)
            left: calc(50% - 80px)

.team-img
    img
        width: 100%
        height: auto

.teams
    &__content
        max-width: 920px
        display: flex
        flex-wrap: wrap
        margin: 0 auto
    &__item
        font-family: $poppins
        color: #fff
        background: $grad
        border-radius: 16px
        flex: 1 1 0
        padding: 16px 24px
        margin: 0 24px 0 0
        position: relative
        &:last-child
            margin: 0
    &__title
        font-weight: 700
        margin: 0 0 16px 0
    &__img
        height: 132px
        text-align: center
        margin: 0 0 20px 0
        img
            width: auto
            height: 100%
    &__name
        line-height: 18px
    &__linkedin
        width: 42px
        height: 42px
        background: url(../../img/icons/linkedin.svg) no-repeat 50%, $grad3
        border-radius: 16px 0 16px 0
        position: absolute
        right: 0
        bottom: 0
    &__facebook
        width: 42px
        height: 42px
        background: url(../../img/icons/facebook.svg) no-repeat 50%, $grad3
        border-radius: 16px 0 16px 0
        position: absolute
        right: 0
        bottom: 0

.ref
    &__item
        width: 100%
        max-width: 560px
        background: #fff
        border-radius: 40px
        box-shadow: 0 8px 18px #00000033
        padding: 24px
        margin: 0 auto 48px auto
    &__title
        font-family: $poppins
        font-size: 48px
        color: var(--blue)
        font-weight: 700
        text-align: center
        background: $grad
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        margin: 0 0 10px 0
    &__subtitle
        font-size: 24px
        text-align: center
    &__sep
        height: 3px
        background: $grad
        margin: 24px -24px
        opacity: 0.3
    &__info
        b
            background: $grad
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
    &__btns
        display: flex
        justify-content: center
    &__btn
        color: #fff
        font-weight: 600
        line-height: 64px
        background: $grad
        border-radius: 40px
        padding: 0 32px
        margin: 0 16px
        cursor: pointer
        &:hover
            filter: brightness(1.1)
        &.copy
            color: var(--blue)
            line-height: 60px
            background: url(../../img/icons/copy.svg) no-repeat 32px 50%
            border: 2px solid var(--blue)
            padding-left: 64px

.list
    &__item
        width: 100%
        max-width: 540px
        height: 300px
        background: #fff
        border: 10px solid #fff
        border-top-width: 20px
        border-bottom-width: 20px
        border-radius: 40px
        box-shadow: 0 10px 20px #00000033
        padding: 0 24px 24px 24px
        margin: 0 0 48px 0
        overflow: auto
        &::-webkit-scrollbar
            width: 10px
            background: #EAEAEA
            border-radius: 8px
        &::-webkit-scrollbar-thumb
            background: $grad
            border-radius: 8px
    &__title
        font-family: $poppins
        font-size: 48px
        font-weight: 700
        text-align: center
        background: $grad
        border-bottom: 2px solid #468AFF40
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        padding: 0 0 24px 0
    &__id
        font-size: 24px
        font-weight: 700
        line-height: 64px
        border-bottom: 2px solid #468AFF40
        div
            font-family: 'Lora', serif
            font-size: 14px
            font-weight: normal
            line-height: 18px
            margin: -16px 0 16px 0
            display: block
            span
                color: var(--blue)
        &:last-child
            border: none

.contact-us
    &__content
        border-radius: 16px
        box-shadow: 0 10px 20px #00000033
        display: flex
    &__info
        color: #fff
        background: $grad
        border-radius: 16px 0 0 16px
        display: flex
        flex-direction: column
        flex: 0 0 420px
        padding: 24px
    &__title
        font-family: $poppins
        font-size: 32px
        font-weight: 700
        margin: 10px 0 24px 0
    &__socials
        margin: auto 0 0 0
    &__form
        flex-grow: 1
        padding: 48px 0
        .form
            max-width: 400px
            margin: 0 auto
            &__glabel
                color: var(--text)

.filter
    width: 100%
    display: flex
    padding: 0 10px
    margin: 0 0 10px 0
    &--wide
        .filter
            &__item
                &:first-child
                    flex: 2 1 0
                &.flex-1
                    flex: 1 1 0
    &__item
        font-size: 12px
        text-align: center
        line-height: 28px
        flex: 1 1 0
        cursor: pointer
        &:hover
            &[data-sort='0']
                &::after
                    background-image: url(../../img/up-n-down__asc.svg)
        &[data-sort='asc']
            color: var(--blue)
            &::after
                background-image: url(../../img/up-n-down__asc.svg)
        &[data-sort='desc']
            color: var(--blue)
            &::after
                background-image: url(../../img/up-n-down__desc.svg)
        &::after
            content: ''
            width: 10px
            height: 18px
            background-image: url(../../img/up-n-down__b.svg)
            background-repeat: no-repeat
            background-position: 50%
            margin: 0 0 0 4px
            display: inline-block
            transform: translateY(3px)
            transition: 0.2s
    &__sep
        flex: 1 1 0

@media (max-width: 1140px)
    .list
        &__content
            .card
                width: 49%
        &__row
            height: auto

@media (max-width: 992px)
    .btn
        line-height: 44px
        padding: 0 32px
        &--big
            &.alt
                line-height: 40px
                &:hover
                    line-height: 44px
                    padding: 0 34px

    .title
        &__big
            h1
                &::after
                    font-size: 14vw

    .card
        border-radius: 10px
        &__img
            height: 200px
        &__price-main
            font-size: 24px

    .sidebar
        width: 240px
        flex: 0 0 240px

    .input
        &__wrap
            input
                max-width: 98px

    .list
        &__row
            padding: 10px
            &--small
                font-size: 11px
        &__cell
            font-size: 12px
            text-align: center
            &-col
                text-align: right
                padding: 0 0 10px 0

    .filter
        padding: 0 10px 0 0
        &__item
            font-size: 11px
            white-space: nowrap
            display: flex
            flex-direction: column
            align-items: center

    .projects
        .container
            padding: 0

    .blog
        .container
            padding: 0
        &__img
            height: 200px

    .sector
        &__title
            font-size: 32px
            margin: 0 0 42px 0

@media (max-width: 768px)
    .content
        &__text
            font-size: 16px
            line-height: 24px
            padding: 0 20px
            margin: 0 0 20px 0
        &__img
            margin: 20px 0

    .btn
        min-width: 200px
        text-align: center
        padding: 0 24px
    .btns
        flex-wrap: wrap
        :first-child
            margin: 0 0 10px 0
    .title
        h1
            font-size: 24px
        h3
            font-size: 14px
        &__big
            margin: 32px 0
            h1
                font-size: 24px
                line-height: 42px
                margin: 0

    .breadcrumbs
        margin: 24px 0 40px 0
        .container
            justify-content: center
        &__title
            font-size: 24px

    .faq
        &__wrap
            flex-direction: column
        &__side
            width: 100%
            display: flex
            flex: 0 0 100%
            padding: 0
            margin: 0 0 24px 0
            overflow: auto
        &__nav
            white-space: nowrap
            padding: 0 24px
        &__question
            font-size: 14px
            line-height: 32px
            padding: 10px 64px 10px 24px
        &__answer
            font-size: 14px

    .features
        .container
            flex-wrap: nowrap
            overflow: auto
        &__item
            flex: 0 0 90%
            margin: 0 8px 0 0
            &:nth-child(2n)
                margin: 0 8px 0 0
        &__title
            font-size: 16px
        &__subtitle
            font-size: 14px

    .hero
        &__item
            flex-wrap: wrap
        &__img
            height: 180px
            flex: 0 0 100%
            img
                border-radius: 20px 20px 0 0
        &__title
            font-size: 24px

    .post
        &__content
            h2
                font-size: 16px
        &__top
            font-size: 16px
            line-height: 24px
            margin: 16px 0 0 0
        &__footer
            flex-wrap: wrap
            align-items: center
            flex-direction: column-reverse
        &__share
            position: unset

    .pagination
        &__item
            width: 32px
            height: 32px
            font-size: 16px

    .teams
        &__content
            max-width: 210px
        &__item
            margin: 0 0 16px 0

    .contact-us
        &__content
            flex-wrap: wrap
        &__info
            border-radius: 16px 16px 0 0
            flex: 0 0 100%
        &__title
            font-size: 24px
            text-align: center
            margin: 0
        &__form
            padding: 32px 16px

    .ref
        &__info
            display: flex
            flex-direction: column
            margin: 0 0 16px 0
        &__btns
            flex-direction: column
            align-items: center
        &__btn
            max-width: 200px
            text-align: center
            line-height: 40px
            margin: 0 0 16px 0
            &.copy
                line-height: 36px
                order: -1

    .list
        &__title
            font-size: 24px

    .roadmap
        &__slider
            margin: 0 0 32px 0
        &__item
            margin: 16px 8px

    .card
        &__slider
            margin: 0 0 24px 0

    .tab
        &__nav
            white-space: nowrap
            padding: 0 24px

    .blog
        &__item
            .btn
                min-width: unset

    .form
        input
            line-height: 60px
        button
            line-height: 40px
            margin: 20px 0 8px 0
        &__btn
            line-height: 40px
        &__info-content
            width: 100%
            left: 0
        &__group
            button
                width: 100%
                position: unset
        &__row
            flex-direction: column-reverse
        &__action
            width: auto
            margin: -34px 0 10px 0
            button
                width: 40%
                line-height: 32px
                margin: -4px 0 0 0
        &__edit
            font-size: 14px
            background-size: 24px

    .subscribe
        input[type=text]
            line-height: 40px
            padding: 0 20px
        input[type=submit]
            line-height: 40px

    .list
        &__wrap
            flex-wrap: wrap
        &__content
            .card
                width: 100%
                &__img
                    height: 50vw
                    min-height: 240px
        &__row
            height: auto
            flex-direction: column
            align-items: flex-start
            padding: 16px
        &__cell
            width: 100%
            font-size: 14px
            line-height: 32px
            display: flex
            justify-content: space-between
            &--md-col
                flex-direction: column
            &:first-child
                border-bottom: 2px solid var(--blue__pale)
                margin: 0 0 10px 0
            &::before
                content: attr(data-title)
            &.up, &.down
                &::before
                    margin: 0 auto 0 0
                &::after
                    transform: translateY(6px)
        &__btn
            width: 100%
            text-align: center
            margin: 10px 0 0 0
            &:nth-last-child(2)
                margin: 8px 0 0 0

    .filter
        flex-wrap: wrap
        &--wide
            .filter
                &__item
                    width: 20%
        &__item
            width: 25%
            flex: auto
            padding: 0 0 10px 0

    .alert
        padding: 20px 30px
        &__icon
            height: 40px
            line-height: 40px
            flex: 0 0 40px
        &--error &__icon
            line-height: 38px

    .sidebar
        width: 100%
        flex: 0 0 100%
        padding: 0
        margin: 0 0 24px 0
        display: none
        &__showfilters
            width: 100%
            color: var(--blue)
            font-weight: 700
            text-align: center
            line-height: 40px
            border: 2px solid var(--blue)
            border-radius: 40px
            margin: 0 0 24px 0
            cursor: pointer
            &::before
                content: ''
                width: 10px
                height: 18px
                background: url(../../img/up-n-down.svg) no-repeat 50%
                margin: 0 10px 0 0
                display: inline-block
                transform: translateY(4px)
            &.active
                color: #fff
                line-height: 44px
                background: $grad
                border: none
                &::before
                    filter: brightness(10)
                + .sidebar
                    display: block

    .bignav
        font-size: 16px
        text-align: center
        &__item
            padding: 0 10px
            &.active
                &::after
                    width: 16px
                    height: 16px

    .chart
        &__wrap
            flex-wrap: wrap
        &__info
            width: 100%
            margin: 0 0 16px 0
        &__graph
            width: 100%

    .stage
        &__thead
            display: none
        &__row
            flex-direction: column
            align-items: center
            span
                &:first-child
                    margin: 0 0 10px 0
                &:nth-child(3)
                    border: none
                    border-top: 1px solid var(--text)
                    border-bottom: 1px solid var(--text)
                    padding: 10px
                    margin: 10px 0

    .doc
        &__title
            font-size: 16px
            line-height: 24px
        &__thead
            display: none
        &__row
            line-height: 20px
            flex-direction: column
            span
                &:nth-child(2)
                    border: none
                    border-top: 1px solid var(--text)
                    border-bottom: 1px solid var(--text)
                    padding: 10px
                    margin: 10px 0