// 
// Theme Name: New Clean Template
// _invest.sass
//
// app/sass/layout/
//

.investment
    &__item
        height: 100%
        color: #fff
        background: $grad
        border-radius: 20px
        box-shadow: 0 10px 20px #00000040
        padding: 24px
        position: relative
        &.main
            margin: 0 0 30px 0
        p
            a
                text-decoration: underline
        .form
            max-width: 320px
            margin: 0 auto
            &--wide
                max-width: 100%
    &__inner
        display: flex
        align-items: center
        justify-content: space-between
        a
            line-height: 32px
            text-decoration: underline
        b
            margin: 0 0 10px 0
    &__title
        font-family: $poppins
        font-size: 24px
        font-weight: 700
        margin: 0 0 10px 0
    &__text
        max-width: 40%
        line-height: normal
        opacity: 0.94
        padding: 10px 0
        &:last-child
            text-align: right
    &__subtext
        max-width: 800px
        font-size: 14px
        line-height: 20px
        padding: 0 20px 0 0
        margin: 0 0 20px 0
        opacity: 0.9
        &:last-child
            margin: 0
        span
            color: var(--green)
    &__img
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        img
            width: 100%
            max-width: 420px
            height: auto
    &__link
        font-size: 14px
        font-weight: 700
        text-decoration: underline
    &__close
        width: 28px
        height: 28px
        background: url(../../img/close.svg) no-repeat 50%
        align-self: flex-start
        cursor: pointer
    &__row
        display: flex
        flex-wrap: wrap
        margin: 0 -15px 30px -15px
    &__col
        width: 50%
        padding: 0 15px
    &__sep
        height: 2px
        background: #ffffff4d
        margin: 20px 0
    &__balance
        margin: 24px 0 8px 0
        span
            color: var(--purple__deep)
    &__binput
        font-size: 16px
        font-weight: 800
        line-height: 64px
        background: url(../../img/euro.svg) no-repeat 24px 50% #fff
        border: none
        border-radius: 40px
        padding: 0 24px 0 60px
        &::-webkit-inner-spin-button
            -webkit-appearance: none
    &__div
        max-width: 50%
    &__wrap
        display: flex
    &__btn
        font-family: $poppins
        font-size: 16px
        font-weight: 600
        line-height: 56px
        letter-spacing: 0.04em
        white-space: nowrap
        align-self: flex-start
        background: linear-gradient(135deg, var(--blue), var(--purple__deep))
        border-radius: 40px
        padding: 0 40px
        margin: 0 32px 0 0
        cursor: pointer
        &:hover
            filter: brightness(1.1)
    &__code
        width: 144px
        font-size: 16px
        font-weight: 700
        letter-spacing: 8px
        border: none
        border-radius: 40px
        padding: 0 40px
        &::placeholder
            color: var(--text)
    &__outro
        font-family: $poppins
        font-size: 40px
        color: #fff
        text-align: center
        display: flex
        flex-direction: column
        align-items: center

@media (max-width: 768px)
    .investment
        &__item
            &.main
                margin: 0 0 24px 0
        &__inner
            flex-wrap: wrap
        &__text
            line-height: 24px
        &__close
            position: absolute
            top: 10px
            right: 10px
        &__col
            width: 100%
            margin: 0 0 24px 0
            &:last-child
                margin: 0
        &__binput
            line-height: 56px
            padding: 0 24px 0 44px
        &__div
            max-width: 100%
            margin: 0 0 24px 0
            &:last-child
                margin: 0
        &__wrap
            flex-wrap: wrap
            justify-content: center
        &__btn
            width: 200px
            font-size: 14px
            text-align: center
            line-height: 40px
            padding: 0 16px
            margin: 0 0 16px 0
            &.accept
                margin: 16px auto 0 auto
        &__code
            width: 200px
            text-align: center
            line-height: 40px