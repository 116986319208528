#project {
  .faq__question {
    border: none;
    transition: border .3s;

    &.active {
      border-top: 2px solid var(--blue);
      border-left: 2px solid var(--blue);
      border-right: 2px solid var(--blue);

      + .faq__answer {
        max-height: 9999px;
        opacity: 1;
      }
    }
  }

  .faq__answer {
    transition: all .3s;
    opacity: 0;
  }

  .swiper {
    .swiper-pagination {
      margin-bottom: 40px;

      .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        font-size: 0;
        background: #ffffff80;
        border: none;
        border-radius: 50%;
        margin: 0 8px;
        cursor: pointer;
        opacity: 1;

        &-active {
          background: linear-gradient(135deg, var(--blue__pale), var(--purple__deep));
        }
      }
    }
  }
}


