@mixin default-notification {
  font-size: 12px;
  line-height: 2rem;
  background: #fff;
  border-radius: 40px;
  box-shadow: 0 8px 18px rgb(0 0 0 / 20%);
  padding: 0 1rem;
  margin: 0 0 16px 0;
  display: block;
  text-align: center;
}

.s-login {
  .auth-error {
    @include default-notification;

    color: #dc3545;
  }

  .label-hidden {
    position: absolute;
    visibility: hidden;
    height: 0;
    margin: 0;
    z-index: -111;
  }

  .form {
    .valid-feedback {
      @include default-notification;
    }

    .invalid-feedback {
      @include default-notification;
    }
  }
}

#register {
  .s-login__tabs {
    .s-login__tab {
      height: 4rem;
      transition: 0.3s;
      color: #fff;

      a {
        line-height: 3rem;
        height: 4rem;
        color: #fff;
        border-radius: 2rem;
      }
    }
  }
}