#knowledge-test {
  .form-check-input {
    transition: all .3s;

    &:checked[type=radio] {
      background: linear-gradient(135deg, var(--blue), var(--green));
    }
  }

  .form--kyc {
    label {
      div {
        input {
          cursor: pointer;
        }
      }
    }

    button {
      max-width: 330px;
      background: linear-gradient(135deg, var(--blue), var(--green));
      margin: 0 auto;
    }
  }
}