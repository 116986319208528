.loader {
  display: flex;
  width: 40px;
  height: 40px;
  position: relative;
  animation: loader 2.5s infinite linear both;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;

  &-white {
    .loader-dot {
      &:before {
        background-color: #fff !important;
      }
    }
  }

  .loader-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: loader-dot 2.0s infinite ease-in-out both;

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-delay: #{(-1.1 + $i * 0.1)}s;

        &:before {
          animation-delay: #{(-1.1 + $i * 0.1)}s;
        }
      }
    }

    &:nth-child(6) {
      &:before {
        background-color: var(--blue);
      }
    }

    &:nth-child(5) {
      &:before {
        background-color: #3b98e9;
      }
    }

    &:nth-child(4) {
      &:before {
        background-color: #33a5d7;
      }
    }

    &:nth-child(3) {
      &:before {
        background-color: #2ab1c5;
      }
    }

    &:nth-child(2) {
      &:before {
        background-color: #18ca9f;
      }
    }

    &:nth-child(1) {
      &:before {
        background-color: var(--green);
      }
    }

    &:before {
      content: '';
      display: block;
      width: 25%;
      height: 25%;
      background-color: #fff;
      border-radius: 100%;
      animation: loader-dot-before 2.0s infinite ease-in-out both;
    }
  }
}

@keyframes loader {
  100% { transform: rotate(360deg); }
}

@keyframes loader-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes loader-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
      transform: scale(1.0);
    }
}