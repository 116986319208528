// 
// Theme Name: New Clean Template
// _header.sass
//
// app/sass/layout/
//

.header
    $header: &
    width: 100%
    height: 100px
    color: #fff
    position: absolute
    top: 0
    z-index: 9
    &::after
        content: ''
        width: 100%
        height: 2px
        background: linear-gradient(135deg, var(--blue), var(--green))
        position: absolute
        top: 100%
        left: 0
        opacity: 0.5
    .container
        height: 100%
        display: flex
        align-items: center
        justify-content: space-between
    nav
        display: flex
        flex-grow: 1
        align-items: center
        margin: 0 auto 0 0
        > a
            line-height: 32px
            padding: 0 16px
            &.active
                font-weight: 600
                position: relative
                &::after
                    content: ''
                    width: 8px
                    height: 8px
                    background: var(--green)
                    border-radius: 50%
                    position: absolute
                    left: 50%
                    bottom: -10px
                    transform: translateX(-50%)
    &__right
        margin: 0 0 0 auto
    &.simple
        color: var(--text)
        background: #fff
        position: relative
        top: auto
        left: auto
        nav
            justify-content: flex-end
    &__submenu
        display: flex
        align-items: center
        border-left: 2px solid #C8DCFF
        padding: 0 0 0 16px
    &__btn
        margin: 0 10px 0 0
        cursor: pointer
        &--lang
            font-size: 24px
            color: var(--blue)
            font-weight: 600
            background: url(../../img/icons/dropdown.svg) no-repeat 100% 50%
            padding: 0 20px 0 0
            margin: 0

.topbar
    color: #fff
    text-align: right
    line-height: 52px
    letter-spacing: 0.02em
    background: linear-gradient(135deg, var(--blue), var(--green))

.nav
    white-space: nowrap
    display: flex
    align-items: center
    ul
        list-style-type: none
        display: flex
        padding: 0
        margin: 0 20px 0 0
        li
            line-height: 42px
            padding: 0 15px

#logo
    &:hover
        #logo_el
            transform: translateX(calc(100% - 24px))
        #logo_o
            opacity: 0
        #logo_i, #logo_n, #logo_l, #logo_e, #logo_n, #logo_d
            fill: url(#logo_gradient)

#logo_el
    transition: 0.4s
#logo_o
    transition: 0.6s
#logo_i, #logo_n, #logo_l, #logo_e, #logo_n, #logo_d
    transition: 0.4s

@media (max-width: 1140px)
    .header
        nav
            a
                padding: 0 10px

@media (max-width: 992px)
    .header
        &.active
            background: var(--green__light)
        nav
            width: 100vw
            height: 100vh
            display: flex
            flex-direction: column
            padding: 120px 0 0 0
            position: absolute
            top: 0
            left: 0
            z-index: -1
            transform: translateX(-100vw)
            transition: 0.4s
            &.active
                transform: translateX(0)
        &__right
            flex-direction: column
            margin: 10px 0
            &.btns
                :first-child
                    margin: 0 0 10px 0

    .nav
        background: $grad
        padding: 0 0 40px 0
        display: none
        ul
            flex-direction: column
        .active &
            width: 100%
            display: flex
            flex-direction: column
            position: absolute
            top: 100px
            left: 0
            z-index: 9

    .login
        width: 80px
        margin: 0 0 20px 0

@media (max-width: 768px)