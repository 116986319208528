#about-us {
  .roadmap__slider {
  }
}

#teams {
  .teams__content {
    .teams__item {
      .teams__name {
        white-space: pre;
      }
    }
  }
}

.contact-us {
  .contact-us__socials {
    a {
      svg {
        margin-right: .35rem;
      }
    }
  }
}