#faq {
  .faq {
    &__wrap {
      .faq {
        &__content {
          .faq {
            &__item {
              .faq {
                &__question {
                  transition: all 0.3s;
                  border: none;

                  &.active {
                    border: 2px solid var(--blue);
                    border-bottom: none;

                    & + [class$=answer] {
                      opacity: 1;
                    }
                  }
                }

                &__answer {
                  opacity: 0;
                  transition: max-height, padding, opacity 0.3s;
                }
              }
            }
          }
        }
      }
    }
  }
}