// scss-docs-start theme-colors-map
$theme-colors: (
  "success":    #87ca81,
  "info":       #2f87eb,
  "warning":    #fec022,
  "danger":     red,
) !default;

@import "~bootstrap/scss/bootstrap";

@import "./sass/common";

@import "pages/index";
@import "pages/login";
@import "pages/dashboard";
@import "pages/project";
@import "pages/projects";
@import "pages/faq";
@import "pages/about-us";
@import "pages/landing";
@import "pages/knowledge-test";
@import "pages/finances";
@import "pages/notifications";


.nav-item:not(.header__btn) {
  .nav-link {
    color: #000000;
  }
}

.dropdown-item-text {
  a {
    display: block;
  }
}

.topbar {
  .container {
    a {
      transition: all .3s;

      svg {
        transition: all .3s;
        fill: #fff;
      }

      &:hover {
        color: #0a58ca;

        svg {
          fill: #0a58ca;
        }
      }
    }
  }
}

nav {
  &.navbar {
    a {
      //color: #000;
    }

    .dropdown {
      &.active {
        &:after {
          content: "";
          width: 8px;
          height: 8px;
          background: var(--green);
          border-radius: 50%;
          position: absolute;
          left: 50%;
          bottom: -10px;
          transform: translateX(-50%);
        }
      }
    }
  }
}