#dashboard {
  .offers {
    .swiper {
      @media (min-width: 1400px) {
        overflow: visible;
      }

      .card {
        width: auto;
      }
    }
  }
}