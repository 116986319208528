// 
// Theme Name: New Clean Template
// _project.sass
//
// app/sass/layout/
//

.project
    &__wrap
        background: #fff
        border-radius: 16px
        box-shadow: 0 10px 20px #00000040
        display: flex
        overflow: hidden
    &__info
        width: 55%
        padding: 24px
    &__row
        display: flex
        justify-content: space-between
        margin: 0 0 16px 0
    &__col
        line-height: 24px
        display: flex
        flex-direction: column
        &:first-child
            padding: 0 10px 0 0
        &:last-child
            text-align: right
        b
            font-family: $poppins
    &__title
        font-family: $poppins
        font-size: 32px
        font-weight: 700
        text-align: center
        line-height: 1.5
        margin: 0 0 40px 0
        animation: none
    &__line-bg
        height: 12px
        background: #EFF0F6
        border-radius: 6px
        margin: 0 0 8px 0
        position: relative
    &__line
        height: 100%
        background: $grad
        border-radius: 6px
    &__goal
        width: 10px
        height: 12px
        background: linear-gradient(135deg, #A9CBF4, #9BDED8)
        position: absolute
        top: 0
        cursor: pointer
    &__goal-hint
        width: 118px
        height: 64px
        font-size: 12px
        text-align: center
        line-height: 16px
        background: #EFF0F6
        border-radius: 16px
        align-items: center
        padding: 0 10px
        position: absolute
        top: 16px
        left: -20px
        display: none
        z-index: 1
        :hover > &
            display: flex
    &__blue
        color: var(--blue)
        font-weight: 700
        span
            color: var(--text)
            font-weight: 400
    &__sep
        height: 2px
        background: var(--blue)
        margin: 24px 0
        opacity: 0.3
    &__form
        fieldset
            legend
                font-size: 16px
                margin: 0 0 10px 0
                span
                    color: var(--blue)
                    text-decoration: underline
                    cursor: pointer
    &__inputarea
        height: 56px
        background: $grad
        border-radius: 48px
        box-shadow: 0 8px 20px #00000040
        display: flex
        justify-content: space-between
        padding: 8px 16px
    &__input
        font-size: 16px
        color: #fff
        letter-spacing: 0.04em
        background: none
        border: none
        flex-grow: 1
        padding: 0 8px
        &:focus
            outline: none
        &::placeholder
            color: #fff
    &__submit
        color: #fff
        font-weight: 700
        line-height: 40px
        letter-spacing: 0.04em
        background: linear-gradient(135deg, var(--blue__pale), var(--purple__deep))
        border: none
        border-radius: 40px
        padding: 0 40px
        cursor: pointer
        -webkit-appearance: none
        &:hover
            background: linear-gradient(135deg, var(--purple__deep), var(--blue__pale))
    &__gallery
        width: 45%
        .slick
            &-list
                width: 100%
                height: 100%
                padding: 0
            &-track
                height: 100%
            &-next
                right: 20px
                opacity: 0.3
                filter: brightness(8)
            &-prev
                left: 20px
                opacity: 0.3
                filter: brightness(8)
            &-slide
                margin: 0
                opacity: 1
            &-dots
                width: 100%
                display: flex
                justify-content: center
                padding: 0
                margin: 0
                position: absolute
                left: 0
                bottom: 40px
                li
                    &.slick-active
                        button
                            background: linear-gradient(135deg, var(--blue__pale), var(--purple__deep))
                    &::after
                        display: none
                button
                    width: 20px
                    height: 20px
                    font-size: 0
                    background: #ffffff80
                    border: none
                    border-radius: 50%
                    margin: 0 8px
                    cursor: pointer
    &__slide
        img
            width: 100%
            height: 100%
            object-fit: cover

@media (max-width: 768px)
    .project
        &__wrap
            flex-wrap: wrap
        &__info
            width: 100%
            padding: 16px
            order: 2
        &__title
            font-size: 24px
            margin: 0 0 24px 0
        &__gallery
            width: 100%
            .slick
                &-dots
                    bottom: 16px
                    li
                        padding: 0
                        display: block
                    button
                        width: 12px
                        height: 12px
                        margin: 0 4px
        &__slide
            height: 200px
        &__input
            max-width: 50%
