#finances {
  .select__all {
    &::after {
      width: 24px;
      height: 24px;
    }
  }

  .form-check-input:checked[type=checkbox] {
    background-image: url(../../assets/img/check__w.svg), linear-gradient(135deg, var(--blue), var(--green));
  }
}